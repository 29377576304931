@use "styles/shared-variables" as *;

.brand-logo {
    display: inline-block;
    max-width: 200px;
    min-width: 50px;
    min-height: 0;
    max-height: 100px;
    width: auto;
    transition: height 0.5s ease;

    &--image {
        @include fade-bounce--in;
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
    }
}

.page.survey-page {
    .brand-logo {
        display: flex;

        &--image {
            object-fit: contain;
            align-self: flex-start;
        }
    }
}

$timing-fast-ease: cubic-bezier(0.05, 0.97, 0, 0.99);
$timing-ease: cubic-bezier(0.26, 0.94, 0.63, 0.97);

@mixin fade--out {
    animation-duration: 0.4s;
    animation-name: fade;
}
@mixin fade--in {
    animation-duration: 0.25s;
    animation-name: fade;
    animation-direction: reverse;
}

@mixin fade-bounce--in {
    animation-duration: 0.2s;
    animation-name: fade-bounce;
    animation-timing-function: $timing-fast-ease;
}
@mixin fade-bounce--out {
    @include fade-bounce--in;
    animation-direction: reverse;
}

@mixin pop--in {
    animation-duration: 0.25s;
    animation-name: pop;
    animation-timing-function: $timing-fast-ease;
    animation-fill-mode: both;
}

@mixin pop--out {
    @include pop--in;
    animation-direction: reverse;
}

@mixin slide-left--in {
    animation-name: slide-left;
    animation-duration: 0.25s;
    animation-timing-function: $timing-fast-ease;
    animation-fill-mode: both;
}

@mixin slide-left--out {
    @include slide-left--in;
    animation-direction: reverse;
}

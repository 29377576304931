@use "styles/shared-variables" as *;
@use "breakpoint-slicer" as breakpoints with (
    $slices: $slicer-breakpoints,
);

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-fill-mode: both;
    margin: 0 auto;
    max-width: 800px;
    min-height: 100%;

    &.page-fade--out {
        @include fade--out;
    }
    &.page-fade--in {
        @include fade--in;
    }

    @include breakpoints.from(tablet) {
        max-width: $tablet;
    }
}
